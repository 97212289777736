<template>
  <div class="containers">
    <!-- 顶部图片 -->
    <div class="myTVTop">
      <el-image class="common-img" :src="myTVImg" fit="cover"></el-image>
    </div>

    <!-- 内容 -->
    <div class="myTVContent">
      <!-- 基础数据信息 -->
      <div class="grid data-info">
        <!-- <el-image class="common-img" :src="dataInfoImg" fit="cover"></el-image> -->
        <div
          class="flex flex-direction align-center justify-center data-info-unit"
          v-for="(item, index) in dataInfo"
          :key="'dataInfo' + index"
        >
          <div
            :class="{
              flex: index == 3 || index == 5
            }"
          >
            <span
              :class="{
                'unit-text__before': index == 3 || index == 5
              }"
              >{{ item.beforeUnit }}
            </span>
            <span
              class="unit-num"
              :style="{
                'letter-spacing': index == 1 || index == 2 ? '-0.21rem' : 0
              }"
            >
              <CountTo
                :startVal="0"
                :endVal="Number(item.num)"
                :duration="1500"
              /><span>{{ item.numUnit }}</span></span
            >
            <span class="unit-text__after">{{ item.unit }}</span>
          </div>
          <div class="unit-text">{{ item.title }}</div>
        </div>
      </div>

      <!-- 栏目介绍 -->
      <div class="column-introduce">
        <!-- <el-image
          class="common-img"
          :src="columnIntroduceImg"
          fit="cover"
        ></el-image> -->
        <div class="title">
          <div class="title-en">COLUMN INTRODUCTION</div>
          <div class="title-zh">栏目介绍</div>
        </div>

        <div class="context">
          《预见独角兽·青创英雄会》由东方卫视、江西卫视和预见独角兽联袂打造，以发掘中国经济源动力为宗旨，
          以成为中国“独角兽企业”最佳展示舞台为目标，聚焦中国民营企业品牌传播的大型真人秀电视栏目。在栏目
          中，企业创始人登上梦想舞台，通过演讲、VCR、与筑梦官分享交流的方式，向两大卫视千万观众展示品牌的
          力量与创始人的梦想，由SMG第一财经主持人刘晔担任青创筑梦人，携手百亿市值企业创始人、顶级投资机构、
          专家学者、头部流量达人组成“筑梦天团”，致力于为品牌在传播、资本、技术等方面赋能提升，照亮创始人成
          就伟大企业家的光辉前路，推动越来越多的“独角兽企业”带动中国经济阔步前行。
        </div>
      </div>

      <!-- 往期风采 -->
      <div class="past-grace">
        <!-- <el-image class="common-img" :src="pastGrace" fit="cover"></el-image> -->
        <div class="title">
          <div class="title-en">PAST STYLE</div>
          <div class="title-zh">往期风采</div>
        </div>

        <el-carousel
          indicator-position="none"
          :height="pastGraceCarouselHeight"
          class="past-grace-carousel"
        >
          <el-carousel-item
            v-for="(item, index) in pastGraceList"
            :key="'pastGrace' + index"
          >
            <el-image class="common-img" :src="item" fit="cover"></el-image>
          </el-carousel-item>
        </el-carousel>
      </div>

      <!-- 嘉宾阵容 -->
      <div class="guest-lineup">
        <!-- <el-image class="common-img" :src="guestLineup" fit="cover"></el-image> -->
        <div class="title">
          <div class="title-en">GUEST LINEUP</div>
          <div class="title-zh">
            嘉宾阵容<span class="title-zh title-zh-sub">（拟邀请）</span>
          </div>
        </div>

        <div class="grid align-center justify-between guest-box">
          <div
            class="flex align-center justify-between guest-content-box"
            v-for="(item, index) in guestList"
            :key="'guest' + index"
          >
            <div class="guest-box-left">
              <el-image
                class="common-img"
                :src="item.img"
                fit="contain"
              ></el-image>
            </div>

            <div class="guest-box-right">
              <div class="guest-type">{{ item.type }}</div>
              <div class="flex flex-direction justify-between guest-content">
                <div class="guest-name">{{ item.name }}</div>
                <div class="guest-desc">{{ item.desc }}</div>
                <div class="guest-context">{{ item.context }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- 栏目人设 -->
      <div class="column-design">
        <div class="title">
          <div class="title-en">COLUMN STAFFING</div>
          <div class="title-zh">栏目人设</div>
        </div>

        <el-image
          class="common-img graphics-box"
          :src="columnDesign"
          fit="cover"
        ></el-image>
        <!-- <div class="graphics-box">
          <div class="origin-point fan lo-fan"></div>
          <div class="origin-point fan lt-fan"></div>
          <div class="origin-point fan ro-fan"></div>
          <div class="origin-point fan rt-fan"></div>
          <div class="origin-point half-circle"></div>
        </div> -->
      </div>

      <!-- 栏目价值 -->
      <div class="column-value">
        <!-- <el-image class="common-img" :src="columnValue" fit="cover"></el-image> -->
        <div class="title">
          <div class="title-en">COLUMN VALUE</div>
          <div class="title-zh">栏目价值</div>
        </div>

        <div class="flex flex-direction">
          <div
            class="flex align-center justify-between"
            style="flex-wrap: nowrap"
          >
            <div
              class="flex flex-direction introduce-box"
              v-for="(item, index) in columnValueList1"
              :key="'columnValue' + index"
            >
              <div class="flex justify-center align-center introduce-img">
                <el-image :src="item.icon" fit="contain" class="img"></el-image>
              </div>
              <div class="introduce-text">{{ item.title }}</div>
            </div>
          </div>

          <div
            class="flex align-center justify-around"
            style="margin-top: 2.5%;"
          >
            <div
              class="flex flex-direction introduce-box"
              v-for="(item, index) in columnValueList2"
              :key="'columnValue' + index"
            >
              <div class="flex justify-center align-center introduce-img">
                <el-image :src="item.icon" fit="contain" class="img"></el-image>
              </div>
              <div class="introduce-text">{{ item.title }}</div>
            </div>
          </div>
        </div>
      </div>

      <!-- 栏目环节 -->
      <div class="column-link">
        <div class="title">
          <div class="title-en">PROGRAM LINK</div>
          <div class="title-zh">栏目环节</div>
        </div>
        <!-- <el-image class="common-img" :src="columnLink" fit="cover"></el-image> -->
        <div class="grid align-center justify-around link-box">
          <div class="link-img img1">
            <el-image
              class="common-img "
              src="http://file.thinkervc.cn/ze0z0588b2qvjz3u7tyso3usdiarnvss931iqq06nkli6ti907qqbm7i1au5uo75u6ut296hqho1nksg.png"
              fit="contain"
            ></el-image>
          </div>
          <div class="dot dot1"></div>
          <div class="link-img img2">
            <el-image
              class="common-img "
              src="http://file.thinkervc.cn/27hwop616338bgthnp9j0t2uzo05vga49gpsybspsjxpulmfish8yskd9wnm9ay2ay7g98q29xif551x.png"
              fit="contain"
            ></el-image>
          </div>
          <div class="dot dot2"></div>
          <div class="link-img img3">
            <el-image
              class="common-img "
              src="http://file.thinkervc.cn/c4prhw6c2vxqsumb2fsn3qkc9oaybe2fublhua789up9hp10ghevbt9svlps26jzi30r8y9fpcww47f5.png"
              fit="contain"
            ></el-image>
          </div>
          <div class="dot dot3"></div>
          <div class="link-img img4">
            <el-image
              class="common-img"
              src="http://file.thinkervc.cn/3yld62aq9lihdccffl7m73r4c8hcdxq064squlrpqyubjpryvg7a4otacmal5ssp3yxn46zfhsnh7msg.png"
              fit="contain"
            ></el-image>
          </div>
          <div class="dot dot4"></div>
          <div class="link-img img5">
            <el-image
              class="common-img"
              src="http://file.thinkervc.cn/bqn5wo772vbzuvltfi0sn04vrnsa725ak8n945ttxc8smx3ebo1uzt5i59c265qwd9trxt80odpf0kqr.png"
              fit="contain"
            ></el-image>
          </div>
        </div>
      </div>

      <!-- 播出平台 -->
      <div class="platform">
        <div class="title">
          <div class="title-en">BROADCAST PLATFORM</div>
          <div class="title-zh">
            播出平台
          </div>
        </div>
        <!-- <el-image class="common-img" :src="platform" fit="cover"></el-image> -->
        <div class="platform-text">卫视平台</div>
        <div class="flex align-center justify-center">
          <div
            class="platform-img"
            v-for="(item, index) in platformImg1"
            :key="'platform1' + index"
          >
            <el-image class="common-img" :src="item" fit="cover"></el-image>
          </div>
        </div>

        <div class="platform-text">网络、新媒体<span>（部分）</span></div>
        <div class="grid align-center justify-center platform-grid-box">
          <div
            class="platform-img"
            v-for="(item, index) in platformImg2"
            :key="'platform1' + index"
          >
            <el-image class="common-img" :src="item" fit="cover"></el-image>
          </div>
        </div>
      </div>
    </div>

    <!-- 投资机构 -->
    <div class="investment">
      <!-- <el-image class="common-img" :src="investment" fit="cover"></el-image> -->
      <div class="title">
        <div class="title-en">INVESTMENT INSTITUTIONS</div>
        <div class="title-zh">
          投资机构<span class="title-zh title-zh-sub">（部分）</span>
        </div>
      </div>

      <div class="investment-carousel-box">
        <el-carousel
          indicator-position="none"
          :height="investmentCarouselHeight"
          class="investment-carousel"
        >
          <el-carousel-item
            v-for="(item, index) in investmentList"
            :key="'investment' + index"
          >
            <div class="flex align-center justify-center" style="height: 100%;">
              <el-image
                class="investment-img"
                :src="item"
                fit="contain"
              ></el-image>
            </div>
          </el-carousel-item>
        </el-carousel>
      </div>
    </div>
  </div>
</template>

<script>
import {
  guestList,
  columnValueList1,
  columnValueList2,
  platformImg1,
  platformImg2,
  investmentList
} from "./data";
import { mapState, mapMutations } from "vuex";

import CountTo from "vue-count-to";

export default {
  name: "MyTV",
  components: {
    CountTo
  },
  data() {
    return {
      myTVImg:
        "http://file.thinkervc.cn/tczd8o865ajm9o053usdg4iniiyr6fwo62ve0689dmahuwfa5vnmlqruq5dmqg9bngznllmq58fogm7p.png",
      // dataInfoImg:
      //   "http://file.thinkervc.cn/qqtdan5s7syl4ppdz4d2akr90qvczqql9n81kcu2v0gekt1u3frm3o2eqt363jmi3bquzm5yfc4frnfz.png",
      // columnIntroduceImg:
      //   "http://file.thinkervc.cn/b2gwjkw76axvoey0i9l2r0dkmdejqxmjhiu9hlkjlm4d3sm8qoggwg7lg6q9u77wsb7cs51hwd9230wl.png",
      // pastGrace:
      //   "http://file.thinkervc.cn/az666rwkoq7ng9k9pbefxy9cw5s8o1e78sairqosta8660utcp5go3xtw8u418ymermhiajtffe9ji9l.png",
      // guestLineup:
      //   "http://file.thinkervc.cn/h7ms7i374kl05u9poin8nw8d8ovnqumo4p8yujuck4qaikqguktg30sw2xt695rngz1s6wejpppk5kks.png",
      // columnValue:
      //   "http://file.thinkervc.cn/qh86q2i08wgt939ogqja9ycnm2vd8rusn9ivei0nmn8a7247f982bb7tb68pnjgnqdfu8258acn6plsp.png",
      // columnLink:
      //   "http://file.thinkervc.cn/106cxpil61mxzssimp3bdzpg36wd7sce8f59gz1ag99a1yoapofs3eozknwfxz5v7jxc90neghq4hzr3.png",
      // platform:
      //   "http://file.thinkervc.cn/02nq63gbel32p5bu1dthy95z8ivc9t9c9ly4usxfvkxsx1zdm7icyej92z6lesqw9ek2y6l4ulwmgl85.png",
      columnDesign:
        "http://file.thinkervc.cn/sdqg05duvbixx9grp1ogz7iecrwzc2ciwiwkvmg03qrytcyobouhub8vb3dfunevezio9nkd8f6jwmfj.png",
      investment:
        "http://file.thinkervc.cn/zb0cdbn2zjq0owq7qmy3a3x2c8vcbeyc2hz6mtmio2v0fm952h11r5h8wiihhpligioyjel0duokiopm.png",

      dataInfo: [
        { title: "已经播出", num: "20", unit: "月" },
        { title: "企业报名参加", num: "100000", numUnit: "＋", unit: "" },
        { title: "投资机构参与", num: "1000", numUnit: "＋", unit: "" },
        { title: "覆盖城市", num: "40", unit: "余座" },
        { title: "聚焦产业", num: "30", numUnit: "＋", unit: "" },
        { title: "累计融资", num: "30", unit: "亿元", beforeUnit: "超" }
      ],

      pastGraceList: [
        "http://file.thinkervc.cn/kcw3ynayhukzw2m6a6d1gf1wkyyirwdtul5xrm7iwlcbi7y2gfhdpetd7wyfvxa83gqf5f6l6as24jz6.png",
        "http://file.thinkervc.cn/d88n032rv5hv6ju9xbyhwlcngtzdd0br5qzpcalqgfk366axskj1vaj0vpamkf8ze3f2y3hwdksizoj4.jpg",
        "http://file.thinkervc.cn/j25le7grlsy48d7o6a65jfqn4uqrkwc969jbrfpe4gu0rcvfjix6rmy8asz8kkv630whu1s06oyrtw2u.jpg",
        "http://file.thinkervc.cn/b412ee72vsb0mfiijgfachffgghkfmd4lz2n13hrlvgeiyxjrc0bmfd5n447i4sd0irbpz9epnmxly89.jpg"
      ],

      guestList,
      columnValueList1,
      columnValueList2,
      platformImg1,
      platformImg2,
      investmentList
    };
  },
  computed: {
    ...mapState({
      isMobile: state => state.isMobile
    }),

    pastGraceCarouselHeight() {
      if (this.isMobile) {
        return "21.5625rem";
      } else {
        return "43.125rem";
      }
    },
    investmentCarouselHeight() {
      if (this.isMobile) {
        return "11.75rem";
      } else {
        return "37.5rem";
      }
    }
  },
  mounted() {},
  methods: {}
};
</script>

<style lang="scss" scoped>
@import "./index.scss";
</style>
